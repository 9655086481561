import { BaseElement, html, css, highlight } from 'Elements';
import { Lang } from 'Utils';

class SelectNaf extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        select-search {
        }


      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.placeholder = '';    
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search-tree').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search-tree').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search-tree').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }
  
  _renderTag(item) {
    return html`
      <sl-tag 
        type="primary" 
        data-id=${item.id} 
        ${this.multiple ? 'removable ': ''}
        size="small" 
        @sl-remove=${this.onRemoveTag}
      >
       ${this.getLabel(this.itemsById[item.id])}
      </sl-tag>
    `;
  }
   
  _getLabel(item) {
    return `${item.code} - ${Lang.lookup(item, this.displayKey)}`;
  }

  _renderTreeItems(items) {

    return items
      .map(item => html`
        <sl-tree-item
          expanded
          class="tree-item ${item.hidden ? 'hidden' : ''}"
          ?selected=${this.isSelected(item)}
          value="${item[this.primaryKey]}"
          .item=${item}
        >
          <span class="tree_label">
            <span>${highlight(this.getLabel(item), this.q)}</span>
            <span class="suffix">
              ${item.job 
                ? item.job.split(',').map(j => html`<sl-badge variant="primary" size="small">${j.toUpperCase()}</sl-badge>`)
                : ''
              }
            </span>
          </span>
          
          ${item.children?.length ? this.renderTreeItems(item.children) : ''}
        </sl-tree-item>
      `);
  }

  render() {
    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search-tree
            value="${this.value}"
            display-key="title"
            separator=","
            name="${this.name}"
            hoist
            selection="leaf"
            leaf-only
            clearable
            placeholder="${this.placeholder}"
            api="private/admin/system/naf"
            .renderTreeItems=${this._renderTreeItems}
            .renderTag=${this._renderTag}
            .getLabel=${this._getLabel}
            primary-key="id"
            @change=${this.onChange}
          >         
          </select-search-tree>
        </div>
      </div>
    `;
  }

}

customElements.define('select-naf', SelectNaf);