import { BaseElement, html, css, highlight } from 'Elements';
import { Lang, Session } from 'Utils';

class SelectRegistry extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
      filter: { type: String },
      selection: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
      nodeId: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.filter = 'people';
    this.selection = 'multiple';
    this.maxOptionsVisible = 2;
    this.leafOnly = true;
    this.placeholder = '';
    this.apiEndPoint = '';
    this.nodeId = '';
    this.rootNodeId = '';
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search-tree').value = this.value;
    }
    if (changedProperties.has('selection')) {
      if (this.selection==='single-all') {
        this.selection = 'single';
        this.leafOnly = false;
      }
      if (this.selection === 'multiple-all') {
        this.selection = 'multiple';
        this.leafOnly = false;
      }
      if (this.selection === 'multiple-leaf') {
        this.selection = 'multiple';
        this.leafOnly = true;
      }
    }

    if (changedProperties.has('nodeId')) {
      // this will update the apiEndPoint
      this.requestUpdate();
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search-tree').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search-tree').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  _renderTag(item) {

    const getTagLabel = (item) => {
      if (item.nodeType === 'contact' || item.nodeType === 'account' || item.nodeType === 'organization' || item.nodeType === 'affiliate' || item.nodeType === 'entity') {
        let currentItem = item;
        let parents = '';
        while (currentItem && currentItem.parentId) {
          currentItem = this.itemsById[currentItem.parentId];
          if (currentItem && ['organization', 'affiliate', 'entity'].includes(currentItem.nodeType)) {
            parents = `${currentItem.name} > ${parents}`;
          }
        }
        if (item.noodType === 'contact' || item.nodeType === 'account') {
          return `${parents} ${item.firstname} ${item.lastname} (${item.email_primary})`;
        } else {
          return `${parents} ${item.name}`;
        }
      }
      return Lang.lookup(item, 'name');
    }

    return html`
      <sl-tag 
        type="primary" 
        data-id=${item.id} 
        ${this.multiple ? 'removable ': ''}
        size="small" 
        @sl-remove=${this.onRemoveTag}
      >
       ${getTagLabel(this.itemsById[item.id])}
      </sl-tag>
    `;
  }
  
  _getLabel(item) {
    if (item.nodeType === 'contact' || item.nodeType === 'account') {
      return `${item.firstname} ${item.lastname} (${item.email_primary})`;
    }
    return Lang.lookup(item, 'name');
  }

  _renderTreeItems(items) {

    return items
      .map(item => html`
        <sl-tree-item
          expanded
          class="tree-item ${item.hidden ? 'hidden' : ''}"
          ?selected=${this.isSelected(item)}
          value="${item[this.primaryKey]}"
          .item=${item}
        >
          <span class="tree_label">
            <span>${highlight(this.getLabel(item), this.q)}</span>
            <span class="suffix">
              ${item.job 
                ? item.job.split(',').map(j => html`<sl-badge variant="primary" size="small">${j.toUpperCase()}</sl-badge>`)
                : ''
              }
            </span>
          </span>
          
          ${item.children?.length ? this.renderTreeItems(item.children) : ''}
        </sl-tree-item>
      `);
  }

  render() {

    if (Session.isCus()) {
      this.apiEndPoint = `public/tickets/registry/search/${this.filter}`;
    } else if (Session.isBoss()) {
      this.apiEndPoint = `private/admin/registry/search/${this.filter}`;
    } else if (Session.isSoc()) {
      this.apiEndPoint = `private/soc/registry/search/${this.filter}`;
    }

    if (!this.apiEndPoint) {
      throw new Error('no apiEndPoint available for user role');
    }

    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search-tree
            value="${this.value}"
            display-key="title"
            separator=","
            name="${this.name}"
            hoist
            selection="${this.selection}"
            ?leaf-only=${this.leafOnly}
            clearable
            max-options-visible="${this.maxOptionsVisible}"
            placeholder="${this.placeholder}"
            nodeId="${this.nodeId}"
            api=${this.apiEndPoint}
            .renderTreeItems=${this._renderTreeItems}
            .renderTag=${this._renderTag}
            .getLabel=${this._getLabel}
            primary-key="id"
            @change=${this.onChange}
          >         
          </select-search-tree>
        </div>
      </div>
    `;
  }

}

customElements.define('select-registry', SelectRegistry);